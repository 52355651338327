<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl" :loading="loading || loadingParent">
		<v-card-title>
			{{ $t('subscriptions.subscriptions') }}
			<v-spacer />
			<v-btn text :to="{ name: 'Pricing' }" v-if="!company.subscriptions.active">
				{{ $t('subscriptions.newSubscription') }}
			</v-btn>
		</v-card-title>
		<v-card-text>
			<SubscriptionList :loading-parent="loadingParent || loading" :subscriptions="subscriptions" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanySubscriptions',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		SubscriptionList: () => import('@/components/subscriptions/SubscriptionList.vue')
	},
	watch: {
		company(val) {
			if (val) {
				this.loading = true
				this.fetchSubscriptionsCompany({ companyID: val.id }).then(() => {
					this.loading = false
				})
			}
		}
	},
	created() {
		if (this.company?.id) {
			this.loading = true
			this.fetchSubscriptionsCompany({ companyID: this.company.id }).then(() => {
				this.loading = false
			})
		}
	},
	computed: {
		...mapGetters({
			subscriptions: 'subscriptions/subscriptions',
			company: 'companies/company'
		})
	},
	methods: {
		...mapActions('subscriptions', ['fetchSubscriptionsCompany'])
	}
}
</script>
